import React from "react";
import { Game, Sport } from "@/types";
import classes from './GameScorecard.module.css';
import { TEAMS } from "../teams";

function transformSport(sport: Sport) {
  return sport.split('_').map((word) => {
    if (word === "mens") word = "Men's";
    if (word === "womens") word = "Women's";
    return word[0].toUpperCase() + word.substring(1);
  })
}

export default function GameScorecard({ game, today }: { game: Game, today?: boolean }) {
  const teamNameClass = today ? classes.todayTeamName : classes.teamName;
  const totalWordClass = today ? classes.todayTotalWord : classes.totalWord;


  return (
    <div key={`${game.sport}-${game.id}`} className={classes.scorecardGame}>
      <img className={classes.slantedHero} src={`/images/today/${game.season}-${game.sport}.png`} />

      <div className={classes.header}>
        <div>{transformSport(game.sport)} {game.vs_string}</div>
        <div className={classes.right}>
          {game.tv_channel !== null ? (`${game.tv_channel} - `) : ''}{game.time_string}
        </div>
      </div>

      <div className={classes.scorecard}>
        {game.state === 'upcoming' ? (
          <div className={classes.logoVersus}> 
            <img src={`/images/team_logos/${TEAMS[game.away_team].logo}`} ></img>
            at
            <img src={`/images/team_logos/${TEAMS[game.home_team].logo}`} ></img>
          </div>
        ) : game.sport === 'volleyball' && (
          <div className={classes.scoreGrid}>
            <div className={classes.header}></div>
            {game.period_range.map((i) => {
              return <div className={classes.header}>{i}</div>;
            })}
            <div className={classes.totalScore}><span className={totalWordClass}>Sets</span></div>
            <div>
              <img src={`/images/team_logos/${TEAMS[game.away_team].logo}`} />
              <span className={teamNameClass}>{game.away_team}</span>
            </div>
            {game.away_score_array.map((score) => {
              return <div>{score}</div>;
            })}
            <div className={classes.totalScore}>{game.away_total_score}</div>
            <div>
              <img src={`/images/team_logos/${TEAMS[game.home_team].logo}`} />
              <span className={teamNameClass}>{game.home_team}</span>
            </div>
            {game.home_score_array.map((score) => {
              return <div>{score}</div>;
            })}
            <div className={classes.totalScore}>{game.home_total_score}</div>
          
          </div>
        )}
        
      </div>
    </div>
  );
}