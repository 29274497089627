import React, { useEffect, useState } from 'react';
import classes from './News.module.css';
import { News } from '@/types';

export default function News() {
  const [news, setNews] = useState<News[]>([]);

  useEffect(() => {
    const getData = async () => {
      const response = await fetch('/home/news.json');
      const newsItems = await response.json();
      const showADonationNews = Math.floor(Math.random() * 5) === 0;

      if (showADonationNews) {
        const position = Math.floor(Math.random() * 4) + 1;
        const hatItemHidden = Math.random() > 0.5;
        const normalItemHidden = !hatItemHidden;

        if (!hatItemHidden) {
          const hatDonationItem = {
            published_at: 0,
            summary: "Support HuskerBase by buying the one-and-only first edition HuskerBase hat. Trucker style, with two patches. Adjustable, durable, and stylish, this hat will be your go-to for years to come.",
            headline: "Need a new hat?",
            sport: null,
            links: [{
              link: 'https://buymeacoffee.com/huskerbase/e/286464',
              source: "Get the HuskerBase Hat"
            }]
          }
  
          newsItems.splice(position, 0, hatDonationItem);
        } else if (!normalItemHidden) {
          const normalDonationItem = {
            published_at: 0,
            summary: "You may have noticed, there are no ads on this page. HuskerBase is committed to remaining ad-free and being by the fans, for the fans. HuskerBase runs on your donations. If you like HuskerBase and want to see it stick around, consider supporting the site by buying us a beer!",
            headline: "By the Fans, For the Fans",
            sport: null,
            links: [{
              link: 'https://buymeacoffee.com/huskerbase',
              source: "Buy Me a Beer!"
            }]
          }

          newsItems.splice(position, 0, normalDonationItem);
        }
      }

      setNews(newsItems);
    };

    getData();
  }, []);

  return (
    <div className='floating-content'>
      <div className={classes.newsBite} style={{ backgroundImage: 'url(/huskerbase_logo.svg)' }}>
        <div className={classes.text}>
          <h1>Welcome to HuskerBase!</h1>
          <p>
            HuskerBase is your one-click site for Husker schedules, scores, standings, news, and more.
            Check out the HuskerBase App, on iOS or Android!
          </p>
        </div>
      </div>

      {news.map((newsItem) => (
        <div className={classes.newsBite} style={{ backgroundImage: 'url(/huskerbase_logo.svg)' }}>
          <div className={classes.text}>
            {newsItem.headline && 
              <h1>{newsItem.headline}</h1>
            }
            <p>
              {newsItem.summary} | {newsItem.links.map((newsLink) => (
                <a href={newsLink.link}>{newsLink.source}</a>
              ))}
            </p>
          </div>
        </div>
      ))}

    </div>
  );
}